import React, { useState } from 'react';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';
import {
    Button,
    FormControl,
    Typography,
} from '../../../../../components/libs';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../hooks';
import { ManualReportsForms } from '../../../../../stores/manualReports';
import moment from 'moment/moment';
import { DatePicker } from '@material-ui/pickers';
import { DayLoadResult } from '../../../../../services/manualReports/requestResponses';
import {
    EnhancedTableHead,
    HeadCell,
} from '../../../../../components/TableHelpers';
import Loader from '../../../../../components/Loader';

const tableHeaders: Array<HeadCell> = [
    { id: 'dayDt', alignment: 'left', label: 'День' },
    { id: 'isOk', alignment: 'left', label: 'Статус' },
    { id: 'errors', alignment: 'left', label: 'Ошибки при загрузке' },
];

const LoadManualReportForm: React.FC = () => {
    const { manualReportsStore, placesStore, generalStore } = useStores();

    const [sinceDt, setSinceDt] = useState<Date>(
        moment().subtract(12, 'days').toDate(),
    );
    const [tillDt, setTillDt] = useState<Date>(
        moment().subtract(11, 'days').toDate(),
    );
    const [loadResult, setLoadResult] = useState<DayLoadResult[] | null>(null);

    const loadManualReports = async () => {
        manualReportsStore.setLoading(true);
        const result = await manualReportsStore.loadManualReports(
            placesStore.currentPlace.id,
            {
                sinceDt,
                tillDt,
            },
        );

        if (result == null) {
            generalStore.showSnackbar('Не удалось скачать даныне');
        } else {
            setLoadResult(result);
        }
        manualReportsStore.setLoading(false);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={manualReportsStore.formsState.LoadForm}
        >
            <DialogTitle>Загрузка отчета за период</DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <FormControl m={4}>
                        <DatePicker
                            inputVariant={'outlined'}
                            format={'dd.MM.yyyy'}
                            label='С'
                            value={sinceDt}
                            onChange={(date: Date) => {
                                setSinceDt(date);
                            }}
                        />
                    </FormControl>
                    <FormControl m={4}>
                        <DatePicker
                            inputVariant={'outlined'}
                            format={'dd.MM.yyyy'}
                            label='По'
                            value={tillDt}
                            onChange={(date: Date) => {
                                setTillDt(date);
                            }}
                        />
                    </FormControl>
                </Box>
                {loadResult && !manualReportsStore.isLoading && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant={'h4'}>
                            Результат загрузки
                        </Typography>
                        <Table
                            aria-labelledby='tableTitle'
                            size={'medium'}
                            aria-label='enhanced table'
                            style={{ marginTop: 10 }}
                        >
                            <EnhancedTableHead cells={tableHeaders} />
                            <TableBody>
                                {loadResult.map((dayResult, index) => (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={`${index}`}
                                    >
                                        <TableCell align='left'>
                                            <Typography variant={'subtitle1'}>
                                                {dayResult.dayDt}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='left'>
                                            <Typography variant={'subtitle1'}>
                                                {dayResult.isOk ? '✅' : '🚫'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='left'>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {dayResult.errors.map(
                                                    (error) => (
                                                        <Typography
                                                            variant={
                                                                'subtitle1'
                                                            }
                                                        >
                                                            {error}
                                                        </Typography>
                                                    ),
                                                )}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color='default'
                    variant='contained'
                    onClick={() =>
                        manualReportsStore.closeForm(
                            ManualReportsForms.LoadForm,
                        )
                    }
                >
                    Отмена
                </Button>
                <Button
                    color='primary'
                    variant={'contained'}
                    disabled={manualReportsStore.isLoading}
                    onClick={() => loadManualReports()}
                >
                    Загрузить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(LoadManualReportForm);
