import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Tab,
    Tabs,
} from '@material-ui/core';
import { Button, Typography } from '../../../../../components/libs';
import { Camera } from '../../../../../services/cameras/models';
import { Video } from '../../../../../services/videos/models';
import {
    getDirectionStr,
    getTimeStr,
    getVideoFileName,
    getWorkStateStr,
} from '../../../../../utils';

interface ShowVideoFormProps {
    open: boolean;
    onClose: () => void;
    camera: Camera;
    video: Video;
}

const ShowVideoForm: React.FC<ShowVideoFormProps> = ({
    open,
    onClose,
    camera,
    video,
}) => {
    const [tabValue, setTabValue] = useState('work');

    const getSrc = () => {
        return `http://192.168.1.7:6005${video.videoPath}`;
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth={'xl'}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
        >
            <DialogTitle id='alert-dialog-title'>
                {camera.title} ({camera.externalId}) {getVideoFileName(video)}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={8} lg={8}>
                        <video
                            controls={true}
                            style={{
                                width: '100%',
                                // height: '100%',
                                // position: 'absolute',
                                // top: 0,
                                // objectFit: 'fill',
                            }}
                            src={getSrc()}
                        ></video>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Grid container item xs={12}>
                            <Tabs
                                value={tabValue}
                                onChange={(event, newValue) =>
                                    setTabValue(newValue)
                                }
                            >
                                <Tab label={'Работа'} value={'work'} />
                                <Tab label={'События'} value={'entries'} />
                            </Tabs>
                        </Grid>
                        {tabValue == 'work' && (
                            <Grid container item xs={12} spacing={2}>
                                {video.monitoringHistory.map((item) => (
                                    <Grid container item xs={12}>
                                        <Grid item xs={6} md={6} lg={6}>
                                            {getTimeStr(item.startAt)} -{' '}
                                            {getTimeStr(item.endAt)}
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}>
                                            {item.vehicleName} (
                                            {getWorkStateStr(item.state)})
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                        {tabValue == 'entries' && (
                            <Grid container item xs={12} spacing={2}>
                                {video.entriesHistory.map((item) => (
                                    <Grid container item xs={12}>
                                        <Grid item xs={6} md={6} lg={6}>
                                            {getTimeStr(item.detectedAt)}
                                        </Grid>
                                        <Grid item xs={6} md={6} lg={6}>
                                            {item.vehicleName} (
                                            {getDirectionStr(item.direction)})
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                        {/*<Typography variant={'body2'}>События:</Typography>*/}
                    </Grid>
                </Grid>
                {/*<DialogContentText id='alert-dialog-description'>*/}
                {/*    Вы уверены, что хотите удалить камеру {camera.title}?*/}
                {/*</DialogContentText>*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Закрыть</Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(ShowVideoForm);
