import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';
import { FormControl, Paper } from '../../../../../components/libs';
import { useStores } from '../../../../../hooks';
import moment from 'moment';
import { DatePicker } from '../../../../../utils/DatePicker';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { Button, Dropdown } from 'rsuite';
import { DownloadReportForm } from '../forms/DownloadReportForm';

const FiltersBlock: React.FC = () => {
    const { placesStore, analyticsStore } = useStores();

    const [isDownloadOpen, setIsDownloadOpen] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<DateRange>([
        moment().subtract(7, 'days').toDate(),
        moment().toDate(),
    ]);

    useEffect(() => {
        if (dateRange?.[0] && dateRange?.[1] && placesStore.currentPlace) {
            analyticsStore.loadAnalytics({
                placeId: placesStore.currentPlace.id,
                sinceDt: moment(dateRange[0]).hour(12).toDate(),
                tillDt: moment(dateRange[1]).hour(12).toDate(),
            });
        }
    }, [dateRange, placesStore.currentPlace?.id]);

    return (
        <Paper p={2}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <FormControl m={4}>
                    <DatePicker
                        onChange={(value) => {
                            setDateRange(value);
                        }}
                        value={dateRange}
                        size={'lg'}
                    />
                </FormControl>
                <FormControl m={4}>
                    {/*<Dropdown trigger={'click'} size={'lg'} title={'Действия'}>*/}
                    {/*    <Dropdown.Item onClick={() => alert('asd')}>*/}
                    {/*        Скачать*/}
                    {/*    </Dropdown.Item>*/}
                    {/*</Dropdown>*/}
                    <Button
                        onClick={() => {
                            setIsDownloadOpen(true);
                        }}
                        appearance={'primary'}
                        size={'lg'}
                        color={'blue'}
                    >
                        Скачать
                    </Button>
                </FormControl>
            </Box>
            <DownloadReportForm
                open={isDownloadOpen}
                dateRange={dateRange}
                onClose={() => setIsDownloadOpen(false)}
            />
        </Paper>
    );
};

export default observer(FiltersBlock);
