import {
    DownloadReportRequest,
    GetVehiclesMonitoringAnalyticsRequest,
    GetVehiclesMonitoringAnalyticsResponse,
} from '../services/analytics/requestResponses';
import { VehicleMonitoringItem } from '../services/analytics/models';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { downloadRequest, request } from '../apiCommandsExecutor';

class AnalyticsStore {
    isLoading: boolean = false;
    vehiclesMonitoringData: VehicleMonitoringItem[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    @action
    setLoading(v: boolean) {
        this.isLoading = v;
    }

    async loadAnalytics(params: GetVehiclesMonitoringAnalyticsRequest) {
        this.setLoading(true);

        const result = await request<GetVehiclesMonitoringAnalyticsResponse>(
            '/analytics/monitoring',
            {
                params: params,
            },
        );

        if (result) {
            runInAction(() => {
                console.log('run in aciton');
                this.vehiclesMonitoringData = result.monitoringData;
            });
        }
        this.setLoading(false);
    }

    async downloadReport(params: DownloadReportRequest) {
        const result = await downloadRequest('/analytics/download', {
            params: params,
        });

        const contDis = result.headers['content-disposition'];
        const filename = contDis.split('=')[1];

        const href = URL.createObjectURL(result.data);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }
}

export default AnalyticsStore;
