import React from 'react';
import './App.css';
import Helmet from 'react-helmet';
import { StylesProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import { MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import maTheme from './theme';
import 'rsuite/dist/rsuite.min.css';

function App(router: any) {
    return (
        <React.Fragment>
            <Helmet
                titleTemplate='%s'
                defaultTitle='Wise Vision - умная видеоаналитика'
            />
            <StylesProvider injectFirst>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                    <MuiThemeProvider theme={maTheme[0]}>
                        <ThemeProvider theme={maTheme[0]}>
                            {router.children}
                        </ThemeProvider>
                    </MuiThemeProvider>
                </MuiPickersUtilsProvider>
            </StylesProvider>
        </React.Fragment>
    );
}

export default App;
