import { ThemeProps } from 'styled-components';
import { Theme } from '@material-ui/core';
import React from 'react';
import { SpacingProps } from '@material-ui/system';

export type GlobalStyleProps = {
    theme: ThemeProps<Theme> & { body: any };
};

export enum RouteType {
    section = 'section',
    route = 'route',
}

export type RouteInfoType = {
    id?: string;
    name: string;
    path: string;
    icon?: JSX.Element;
    routes?: Array<RouteInfoType>;
    component: React.ReactNode | null;
    badge?: string | number;
    header?: string;
    hide?: boolean;
    type?: RouteType;
    redirect?: string;
    index?: boolean;
    roles?: string[];
};

export interface MuiButtonSpacingType extends SpacingProps {
    component?: React.PropsWithoutRef<{}>;
    to?: string;
}
