import { DateRangePicker, DateRangePickerProps } from 'rsuite';
import React from 'react';
import moment from 'moment';
import { DateRange, RangeType } from 'rsuite/DateRangePicker';

const ranges: RangeType<DateRange>[] = [
    {
        label: 'Сегодня',
        value: [
            moment().startOf('day').toDate(),
            moment().endOf('day').toDate(),
        ],
    },
    {
        label: 'Вчера',
        value: [
            moment().subtract(1, 'day').startOf('day').toDate(),
            moment().subtract(1, 'day').endOf('day').toDate(),
        ],
    },
    {
        label: 'Неделя',
        value: [
            moment().subtract(1, 'week').startOf('day').toDate(),
            moment().endOf('day').toDate(),
        ],
    },
    {
        label: '2 недели',
        value: [
            moment().subtract(2, 'week').startOf('day').toDate(),
            moment().endOf('day').toDate(),
        ],
    },
    {
        label: 'С начала месяца',
        value: [
            moment().startOf('month').startOf('day').toDate(),
            moment().endOf('day').toDate(),
        ],
    },
];

interface DatePickerProps extends DateRangePickerProps {}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
    return (
        <DateRangePicker
            style={{
                background: '#F1F6FC',
                borderRadius: 5,
            }}
            ranges={ranges}
            format={'dd/MM/yyyy'}
            {...props}
        />
    );
};
