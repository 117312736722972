import { Button, FormControl, Paper } from '../../../../../components/libs';
import React from 'react';
import { Box } from '@material-ui/core';
import { useStores } from '../../../../../hooks';
import { ManualReportsForms } from '../../../../../stores/manualReports';
import { DatePicker } from '../../../../../utils/DatePicker';

const FiltersBlock: React.FC = () => {
    const { manualReportsStore } = useStores();
    return (
        <Paper p={2}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    <DatePicker />
                    {/*<FormControl m={4}>*/}
                    {/*    <DatePicker*/}
                    {/*        inputVariant={'outlined'}*/}
                    {/*        format={'dd.MM.yyyy'}*/}
                    {/*        label='С'*/}
                    {/*        value={null}*/}
                    {/*        onChange={(date: Date) => {*/}
                    {/*            // setSinceDt(date);*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</FormControl>*/}
                    {/*<FormControl m={4}>*/}
                    {/*    <DatePicker*/}
                    {/*        inputVariant={'outlined'}*/}
                    {/*        format={'dd.MM.yyyy'}*/}
                    {/*        label='По'*/}
                    {/*        value={null}*/}
                    {/*        onChange={(date: Date) => {*/}
                    {/*            // setSinceDt(date);*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*</FormControl>*/}
                </Box>
                <Box
                    sx={{
                        alignContent: 'center',
                    }}
                >
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() =>
                            manualReportsStore.openForm(
                                ManualReportsForms.LoadForm,
                            )
                        }
                    >
                        Загрузить отчеты
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default FiltersBlock;
