import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, FlexboxGrid, Modal, Text } from 'rsuite';
import { DatePicker } from '../../../../../utils/DatePicker';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { useStores } from '../../../../../hooks';
import moment from 'moment';

interface DownloadReportFormProps {
    open: boolean;
    dateRange: DateRange;
    onClose: () => void;
}

export const DownloadReportForm: React.FC<DownloadReportFormProps> = ({
    open,
    dateRange,
    onClose,
}) => {
    const { analyticsStore, placesStore } = useStores();
    const [dates, setDates] = useState<DateRange>(dateRange);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setDates(dateRange);
        }
    }, [open]);
    const downloadReport = async () => {
        if (dates[0] && dates[1]) {
            setIsLoading(true);
            await analyticsStore.downloadReport({
                placeId: placesStore.currentPlace.id,
                sinceDt: moment(dates[0]).hour(12).toDate(),
                tillDt: moment(dates[1]).hour(12).toDate(),
            });
            setIsLoading(false);
            onClose();
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Title>Отчет о работе за период</Modal.Title>
            <Modal.Body>
                <FlexboxGrid align={'middle'}>
                    <Text style={{ marginRight: 5 }} size={'lg'}>
                        Период:
                    </Text>
                    <DatePicker
                        value={dates}
                        size={'md'}
                        onChange={(value) => {
                            setDates(value);
                        }}
                    />
                </FlexboxGrid>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose}>Отмена</Button>
                <Button
                    onClick={downloadReport}
                    appearance={'primary'}
                    disabled={!dates}
                    loading={isLoading}
                >
                    Скачать
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
