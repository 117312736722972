import App from '../App';
import {
    createBrowserRouter,
    IndexRouteObject,
    Navigate,
    NonIndexRouteObject,
    RouteObject,
    RouterProvider,
} from 'react-router-dom';
import { StoreProvider } from '../contexts';
import rootStore from '../stores';
import React from 'react';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import { RouteInfoType, RouteType } from '../types/types';
import SecurityLayout from '../layouts/SecurityLayout';
import authRoutes from '../modules/auth/routes';
import adminRoutes from '../modules/analytics/routes';
import settingsRoutes from '../modules/global-settings/routes';
import placeSettingsRoutes from '../modules/place-settings/routes';
import qualityRoutes from '../modules/quality/routes';

const authorizedRoutes = [
    adminRoutes,
    placeSettingsRoutes,
    settingsRoutes,
    qualityRoutes,
];

export const config = [
    authRoutes,
    {
        id: 'common',
        path: '/',
        redirect: '/analytics/dashboard',
        name: 'Общие',
        hide: false,
        component: <SecurityLayout />,
        routes: [...authorizedRoutes],
    },
];

const convertRoutes = () => {
    return [
        renderRouteInfoType(authRoutes),
        {
            path: '/',
            element: <SecurityLayout />,
            children: authorizedRoutes.map(renderRouteInfoType),
        },
    ];
};

const renderRouteInfoType = (routeItem: RouteInfoType): RouteObject => {
    if (routeItem.routes && routeItem.routes.length > 0) {
        const renderedRoutes = routeItem.routes
            .filter((t) => t.type !== RouteType.section)
            .map(renderRouteInfoType);
        return {
            path: routeItem.path,
            element: <ErrorBoundary>{routeItem.component}</ErrorBoundary>,
            children: renderedRoutes,
        };
    } else {
        if (routeItem.redirect) {
            return {
                path: routeItem.path,
                element: <Navigate replace to={routeItem.redirect} />,
                index: routeItem.index,
            } as NonIndexRouteObject | IndexRouteObject;
        }

        return {
            path: routeItem.path,
            element: routeItem.component,
            index: routeItem.index,
        } as NonIndexRouteObject | IndexRouteObject;
    }
};

function RouterConfig() {
    return (
        <App>
            <StoreProvider store={rootStore}>
                <RouterProvider router={createBrowserRouter(convertRoutes())} />
            </StoreProvider>
        </App>
    );
}

export default RouterConfig;
