import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getToken } from './token';
import { ExecutionStatus, GeneralResponse } from './api';

export const API_URL = '';
// export const API_URL = 'https://vision.wisecity.systems';
// export const API_URL = 'http://localhost:5004';

export const request = async <ResponseType>(
    url: string,
    config?: AxiosRequestConfig,
) => {
    const result: AxiosResponse<GeneralResponse<ResponseType>> =
        await axios.request({
            ...config,
            url: url,
            baseURL: `${API_URL}/api`,
            headers: {
                Authorization: `Bearer ${getToken()}`,
                'Content-Type': 'application/json',
            },
            validateStatus: () => true,
        });

    if (result.data && result.data.status == ExecutionStatus.finished) {
        return result.data.result;
    }

    return null;
};

export const downloadRequest = async (
    url: string,
    config?: AxiosRequestConfig,
) => {
    const result: AxiosResponse = await axios.request({
        ...config,
        url: url,
        baseURL: `${API_URL}/api`,
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
        },
        responseType: 'blob',
        validateStatus: () => true,
    });

    return result;
};
