import React, { useEffect, useMemo } from 'react';
import { useStores } from '../../../../hooks';
import PlaceSelect from '../../../../components/PlaceSelect';
import FiltersBlock from './components/FiltersBlock';
import LoadManualReportForm from './forms/LoadManualReportForm';

const ManualReportsPage: React.FC = () => {
    const { generalStore, placesStore, manualReportsStore } = useStores();

    useEffect(() => {
        generalStore.setPageTitle('Отчеты для проверки', [<PlaceSelect />]);
    }, []);

    return (
        <>
            <FiltersBlock />
            <LoadManualReportForm />
        </>
    );
};

export default ManualReportsPage;
